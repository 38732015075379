import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import CategoriesNav from './partials/categories-nav';
import Layout from '../layout';
import Container from '../components/container';
import BlogPreview from '../components/blog-preview';
import Pagination from '../components/pagination';
import Seo from '../components/seo';
import { useGetSiteMetadata } from '../context/siteMetadataContext';

import i18n from '../i18n';

const BlogOverview = ({ data, pageContext }) => {
  const posts = data.postsByCategory.edges;
  const categories = data.categories.edges || [];
  const category = data.category?.frontmatter?.categoryTitle ? data.category.frontmatter : null;
  const { newsPath } = useGetSiteMetadata();
  return (
    <Layout bodyBackground="gray1">
      {category && (<Seo {...category.meta} slug={`${newsPath}/`} />)}
      {!category && (
        <Seo
          metaTitle={i18n('blogMetaTitle', 'Blog')}
          metaDescription={i18n('blogMetaDescription', 'Alle blogs over het hosten van Magento Shopware en andere platformen')}
          slug={newsPath}
        />
      )}
      <Container>
        <CategoriesNav categories={categories} />
        <Wrapper>
          {posts.map(({ node }, index) => (
            <BlogPreview key={`key-${index}`} {...node} />
          ))}
          {posts.length === 0 && (
            <div>
              <br />
              <h1>No blog posts found.</h1>
            </div>
          )}
        </Wrapper>
        {pageContext.numberOfPages > 1 && (
          <Pagination
            {...pageContext}
            path={pageContext.pathPrefix}
          />
        )}
      </Container>
    </Layout>
  );
};

export default BlogOverview;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  padding: 1rem 0 2rem 0;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const pageQuery = graphql`
  query($categoryId: String, $skip: Int!, $limit: Int!) {
    postsByCategory: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "blog" },
          categories: {
            elemMatch: { id: { eq: $categoryId } }
          },
          state: { eq: "published" }
        }
      }
      sort: {
        fields: frontmatter___date, order: DESC
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            ...BlogPreview
          }
        }
      }
    }
    category: markdownRemark( id: { eq: $categoryId } ) {
      frontmatter {
        categoryTitle
        ...MetaTags
      }
    }
    categories: allMarkdownRemark(
      filter: {
        frontmatter: {
          categoryTitle: { ne: null }
        }
      },
      sort: {fields: frontmatter___order}
    ) {
      edges {
        node {
          id
          frontmatter {
            categoryTitle
            slug
          }
        }
      }
    }
  }
`;
