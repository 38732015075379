import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import PreviewCompatibleImage from './preview-compatible-image';
import Content from './content';

export const query = graphql`
  fragment BlogPreview on MarkdownRemarkFrontmatter {
    slug
    title
    excerpt
    author {
      frontmatter {
        authorName
      }
    }
    date(
      formatString: "D MMMM YYYY"
      locale: "nl-NL"
    )
    featuredBlogImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 758, maxHeight: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
`;

const BlogPreview = ({ frontmatter }) => (
  <Wrapper>
    <Preview>
      {frontmatter.featuredBlogImage && (
        <StyledImage
          image={frontmatter.featuredBlogImage}
          alt={frontmatter.title}
        />
      )}
      <div css="padding: 1rem 1rem 3rem 1rem;">
        <Date>{frontmatter.date}</Date>
        <Title>{frontmatter.title}</Title>
        <Content>
          <p>{frontmatter.excerpt}</p>
        </Content>
        <Author>{frontmatter.author?.frontmatter.authorName}</Author>
      </div>
      <StyledLink to={frontmatter.slug} />
    </Preview>
  </Wrapper>
);


export default BlogPreview;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Author = styled.span`
  position: absolute;
  bottom: .5rem;
  right: 1rem;
  color: ${(props) => props.theme.primaryColor};
`;

const Date = styled.span`
  display: block;
  color: ${(props) => props.theme.gray3};
  padding: .5rem 0;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.primaryColor};
  font-size: 2rem;
  line-height: 1.2;
  background: linear-gradient(270deg,${(props) => props.theme.primaryColor},${(props) => props.theme.secondaryColor});
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: .75rem;
`;

const Wrapper = styled.div`
  filter: drop-shadow(0 5px 5px rgba(0,0,0,.5));
  display: flex;
`;

const Preview = styled.article`
  position: relative;
  border-radius: .5rem .5rem 0 0;
  clip-path: polygon(0 0,100% 0,100% 100%,50px 100%,0 calc(100% - 50px));
  background-color: white;
  width: 100%;
`;

const StyledImage = styled(PreviewCompatibleImage)`
  border-radius: .5rem .5rem 0 0;
`;
