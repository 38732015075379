import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Icon from '../layout/icons';

const activeClassname = 'active';

const Pagination = ({
  className,
  previousPagePath,
  nextPagePath,
  numberOfPages,
  path,
}) => {
  const pages = [...Array(numberOfPages).keys()];
  return (
    <div className={className}>
      <Wrapper>
        <div>
          {previousPagePath && (
            <Link to={previousPagePath}>
              <Icon icon="chevron-left" />
            </Link>
          )}
        </div>
        <div>
          {pages.map((page, index) => {
            const link = page + 1;
            return (
              <PageLink
                key={`${link}-${index}`}
                to={`${path}${link === 1 ? '' : `/${link}`}`}
                activeClassName={activeClassname}
              >
                {link}
              </PageLink>
            );
          })}
        </div>
        <div>
          {nextPagePath && (
            <Link to={nextPagePath}>
              <Icon icon="chevron-right" />
            </Link>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

const PageLink = styled(Link)`
  padding: .5rem;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default styled(Pagination)`
  padding-bottom: 1rem;
  ${Icon} {
    width: 1rem;
  }
  a {
    color: ${(props) => props.theme.fontColor};
    &.${activeClassname},
    &:hover {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;
