import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Icon from '../../layout/icons';

import i18n from '../../i18n';

const activeClassname = 'active';

const CategoriesNav = ({ categories }) => {
  const [menuIsOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuIsOpen);
  return (
    <>
      {categories.length > 0 && (
        <>
          <CategoriesWrapper>
            <TitleWrapper>
              <h5>{i18n('blogCategoriesTitle', 'Populaire categorieën:')}</h5>
              <Toggle onClick={toggleMenuOpen}>
                <Icon icon="hamburger" />
              </Toggle>
            </TitleWrapper>
            <div>
              <Categories menuIsOpen={menuIsOpen}>
                {categories.map(({ node }, index) => (
                  <div key={`${node.frontmatter.categoryTitle}}-${index}`}>
                    <StyledLink
                      to={node.frontmatter.slug}
                      activeClassName={activeClassname}
                    >
                      {node.frontmatter.categoryTitle}
                    </StyledLink>
                  </div>
                ))}
              </Categories>
            </div>
          </CategoriesWrapper>
        </>
      )}
    </>
  );
};

const Toggle = styled.button`
  background-color: transparent;
  display: block;
  padding: 0;
  border: none;
  cursor: pointer;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: none;
  }
  ${Icon} {
    width: 1.5rem;
  }
`;

const CategoriesWrapper = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: .5rem;
  margin-top: 2rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: block;
  }
  h5 {
    font-size: 1rem;
  }
`;

const Categories = styled.div`
  text-align: center;
  display: none;
  ${(props) => props.menuIsOpen && css`
    display: block;
  `};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: initial;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: .5rem;
  color: ${(props) => props.theme.gray3};
  text-decoration: none;
  &:hover,
  &.${activeClassname} {
    color: ${(props) => props.theme.primaryColor};
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding: 0 0 0 1rem;
  }
`;

export default styled(CategoriesNav)``;
